import { isNullOrEmpty } from "../../utils/arrays";
import dayjs from 'dayjs';

export const isReadOnly = (assignment) => {
  return assignment.submissionCount !== 0 && assignment.status != 'DRAFT';
};

export const isTaskNotSetUp = (questions) =>{
  return !isNullOrEmpty(questions) && questions[0].question === '';
}

export const isFeedbackMethodNotSelected = (reviewedBy) =>{
  return reviewedBy === 'NONE';
}

export const noClassSelected = (classIds) =>{
  return classIds.length === 0;
}

export const isDueDateNotSet = (dueAt) => {
  if (!dueAt) return true;
  
  
  return false;
}

export const isDueDateOneHourAfterPublish = (publishScheduledAt, dueAt) => {
  if (!publishScheduledAt || !dueAt) return false;
  
  const publishDate = dayjs(publishScheduledAt);
  const dueDate = dayjs(dueAt);

  // Check if publish date is in the future and due date is at least 1 hour after publish date
  return  dueDate.diff(publishDate, 'minute') >= 60;
}

export const shouldShowSaveAsDraftButton = (assignment) => {
  return assignment.status === 'DRAFT';
};
export const shouldShowPublishNowButton = (assignment) => {
  return assignment.status === 'DRAFT' || assignment.status === 'SCHEDULED' || assignment.status === 'PUBLISHED';
};

export const shouldShowDeleteButton = (assignmentId, assignment) => {
  return assignmentId !== 'new' && assignment.status == 'PUBLISHED' || assignment.status == 'SCHEDULED';
};
