import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  TitleContainer,
  DeleteButtonContainer,
  Frame1322,
  IconTrash,
  Delete,
  SLink,
  Frame1379,
  Frame1376,
  Frame1315,
  Frame1378,
  Frame1375,
  Frame1372,
  Title, // ensure only one Title is imported
  Frame1374,
  TextInput,
  Frame1294,
  Questions,
  Frame1295,
  AssignmentSettings,
  Frame1296,
  Frame1377,
  Frame1299,
  Frame12811,
  Classes,
  Frame1298,
  Frame12981,
  Frame12191,
  Buttons1,
  Button,
  TitleAndLinkContainer,
  LinkContainer,
  TitleImage,
  HeadingLine,
  TitleAndSubtitleContainer,
  TaskNameContainer,
  TaskHeadingContainer,
  TaskHeading,
  TaskContainer,
  TaskName,
  TaskTitle,
  StepsContainer,
  StepsPart,
  StepContainer,
  StepImag,
  StepText,
  MainContainer,
  StepNum,
  LeftStepNumber,
  TitleAndSubtitleSubContainer,
  LinkIcon,
  LinkText,
  LinkPartContainer,
  ScheduledTaskContainer,
  ScheduledDate,
  TimeSelectionContainer,
  RadioContainer,
  DateTimeContainer,
} from './CreateAssignmentLaptopStyle';

import Buttons2 from '../Buttons2';
import GoBack2 from '../GoBack2';
import { isMobileView, isSmallScreen } from '../ReactiveRender';
import Footer from '../Footer';
import QuestionTooltip from '../../components2/QuestionTooltip';
import questionMark from '../../static/img/question-mark.svg';
import RoundedBorderSubmitBtn from '../../components2/Buttons/RoundedBorderSubmitBtn';
import NoBgBigRoundedBlackBorder from '../../components2/Buttons/NoBgBigRoundedBlackBorder';
import NoBackgroundAndBorderBtn from '../../components2/Buttons/NoBackground&BorderBtn';
import {
  isTaskNotSetUp,
  isReadOnly,
  isFeedbackMethodNotSelected,
  noClassSelected,
  shouldShowSaveAsDraftButton,
  shouldShowPublishNowButton,
  shouldShowDeleteButton,
  isDueDateNotSet,
} from './rules';
import { isNullOrEmpty } from '../../utils/arrays';
import TopBannerBox from '../../components2/TopBannerBox';
import { FormControlLabel, RadioGroup } from '@mui/material';
import { getPublishButtonConfig, isShowPublishedStatus, isShowSelectPublishSchedule, publishButtonLabel } from '../../rules';

function CreateAAssignmentLaptop(props) {
  const {
    assignmentId,
    addQuestion,
    questionFrames,
    handleTitleChange,
    publish,
    saveDraft,
    checkboxes,
    assignment,
    dateSelectorFrame,
    scheduleDateSelectorFrame,
    PublishTimeSelectionContainer,
    feedbacksMethodContainer,
    cleanformattingTextBox,
    buttons21Props,
    goBack22Props,
    showDeletePopuphandler,
    showPublishPopuphandler,
    showScheduledPopupHandler,
    showUpdatePopuphandler,

    submissions,
  } = props;

  const smallScreenView = isSmallScreen();
  const mobileView = isMobileView();

  const [showBanner, setShowBanner] = useState(false);
  const [timeOption, setTimeOption] = React.useState('now');
  const bannerTextForAcceptedAssignment =
    'This task can not be updated as students have already started working on it.';

  useEffect(() => {
    if (submissions) {
      setShowBanner(!isNullOrEmpty(submissions));
    }
  }, [submissions]);

  function titleAndSaveButtons(assignment, saveDraft, publish) {
    const title =
      
        <TaskHeading>
          <Title>Create Task</Title>
          <QuestionTooltip
            img={questionMark}
            text={
              'Follow the steps provided to design the perfect task for your classes'
            }
          />
        </TaskHeading>
      ;
    return (
      <TitleAndLinkContainer>
        <TitleAndSubtitleContainer>
          <TitleContainer>
            {title}
            {saveButtons(
              assignment,
              saveDraft,
              showScheduledPopupHandler,
              publish,
              showPublishPopuphandler,
              showUpdatePopuphandler
            )}
          </TitleContainer>
          <HeadingLine style={{ display: mobileView ? 'none' : 'flex' }}>
            Follow the steps below to create and assign a task for your classes
          </HeadingLine>
        </TitleAndSubtitleContainer>
      </TitleAndLinkContainer>
    );
  }

  const saveButtons = (
    assignment,
    saveDraft,
    showScheduledPopupHandler,
    publish,
    showPublishPopuphandler,
    showUpdatePopuphandler
  ) => {

    const handlePublishAction = (e) => {
      const { actionType } = getPublishButtonConfig(assignment);
      
      const actions = {
        SCHEDULED: showScheduledPopupHandler,
        PUBLISH: () => showPublishPopuphandler(e),
        UPDATE: showUpdatePopuphandler
      };
  
      actions[actionType]();
    };

    const buttonConfig = getPublishButtonConfig(assignment);
    return  <Frame12191>
      {shouldShowDeleteButton(assignmentId, assignment) && <Frame1322 onClick={showDeletePopuphandler}>
              <IconTrash src="/icons/trashcan.svg" alt="icon-trash" />
              <Delete>Delete</Delete>
            </Frame1322>}
          {shouldShowSaveAsDraftButton(assignment) && <NoBgBigRoundedBlackBorder
                text={'Save as draft'}
                onclick={(e) => saveDraft(e)}
          />}
          {shouldShowPublishNowButton(assignment) && (
            <RoundedBorderSubmitBtn
              text={buttonConfig.label}
              onClickFn={handlePublishAction}
            />
          )}
        
        {/* {
          shouldShowDeleteButton(assignmentId, assignment) && <DeleteButtonContainer>
            <Frame1322 onClick={showDeletePopuphandler}>
              <IconTrash src="/icons/trashcan.svg" alt="icon-trash" />
              <Delete>Delete</Delete>
            </Frame1322>
            <RoundedBorderSubmitBtn
              text="Update"
              onClickFn={showUpdatePopuphandler}
            />
          </DeleteButtonContainer>
        } */}
      </Frame12191>
  };

  return (
    <>
      {showBanner && (
        <TopBannerBox
          bannerText={bannerTextForAcceptedAssignment}
          showBannerButton={false}
          openBanner={showBanner}
          setOpenBanner={setShowBanner}
          showTooltip={bannerTextForAcceptedAssignment > 75}
        />
      )}
      <MainContainer notEditable={submissions.length > 0}>
        <Frame1379>
          {titleAndSaveButtons(assignment, saveDraft, publish)}
          <Frame1378 readOnly={isReadOnly(assignment)}>
            {!smallScreenView && (
              <StepsPart>
                <StepsContainer>
                  <StepContainer>
                    <StepNum
                      style={{
                        backgroundColor: isNullOrEmpty(assignment.title)
                          ? '#fff'
                          : '#7200E0',
                        color: isNullOrEmpty(assignment.title)
                          ? '#918b97'
                          : '#fff',
                        border: isNullOrEmpty(assignment.title)
                          ? '1.5px solid #918b97'
                          : '1.5px solid #7200E0',
                      }}
                    >
                      1
                    </StepNum>
                    <StepText
                      style={{
                        color: isNullOrEmpty(assignment.title)
                          ? '#918b97'
                          : '#7200E0',
                      }}
                    >
                      Name the task
                    </StepText>
                  </StepContainer>
                  <StepContainer>
                    <StepNum
                      style={{
                        backgroundColor: noClassSelected(assignment.classIds)
                          ? '#fff'
                          : '#7200E0',
                        color: noClassSelected(assignment.classIds)
                          ? '#918b97'
                          : '#fff',
                        border: noClassSelected(assignment.classIds)
                          ? '1.5px solid #918b97'
                          : '1.5px solid #7200E0',
                      }}
                    >
                      2
                    </StepNum>
                    <StepText
                      style={{
                        color: noClassSelected(assignment.classIds)
                          ? '#918b97'
                          : '#7200E0',
                      }}
                    >
                      Select a class
                    </StepText>
                  </StepContainer>
                  <StepContainer>
                    <StepNum
                      style={{
                        backgroundColor: isTaskNotSetUp(assignment.questions)
                          ? '#fff'
                          : '#7200E0',
                        color: isTaskNotSetUp(assignment.questions)
                          ? '#918b97'
                          : '#fff',
                        border: isTaskNotSetUp(assignment.questions)
                          ? '1.5px solid #918b97'
                          : '1.5px solid #7200E0',
                      }}
                    >
                      3
                    </StepNum>
                    <StepText
                      style={{
                        color: isTaskNotSetUp(assignment.questions)
                          ? '#918b97'
                          : '#7200E0',
                      }}
                    >
                      Set up the task
                    </StepText>
                  </StepContainer>
                  <StepContainer>
                    <StepNum
                      style={{
                        backgroundColor: isFeedbackMethodNotSelected(
                          assignment.reviewedBy
                        )
                          ? '#fff'
                          : '#7200E0',
                        color: isFeedbackMethodNotSelected(
                          assignment.reviewedBy
                        )
                          ? '#918b97'
                          : '#fff',
                        border: isFeedbackMethodNotSelected(
                          assignment.reviewedBy
                        )
                          ? '1.5px solid #918b97'
                          : '1.5px solid #7200E0',
                      }}
                    >
                      4
                    </StepNum>
                    <StepText
                      style={{
                        color: isFeedbackMethodNotSelected(
                          assignment.reviewedBy
                        )
                          ? '#918b97'
                          : '#7200E0',
                      }}
                    >
                      Select the feedback method
                    </StepText>
                  </StepContainer>
                  <StepContainer>
                    <StepNum style={{
                      backgroundColor: false
                        ? '#fff'
                        : '#7200E0',
                      color: false
                        ? '#918b97'
                        : '#fff',
                      border: false
                        ? '1.5px solid #918b97'
                        : '1.5px solid #7200E0',
                    }}>
                      5
                    </StepNum>
                    <StepText
                      style={{
                        color : false
                          ? '#918b97'
                          : '#7200E0',
                      }}
                    >
                      Select the publish time
                    </StepText>
                  </StepContainer>
                  <StepContainer>
                    <StepNum style={{
                      backgroundColor: isDueDateNotSet(assignment.dueAt)
                        ? '#fff'
                        : '#7200E0',
                      color: isDueDateNotSet(assignment.dueAt)
                        ? '#918b97'
                        : '#fff',
                      border: isDueDateNotSet(assignment.dueAt)
                        ? '1.5px solid #918b97'
                        : '1.5px solid #7200E0',
                    }}>
                      6
                    </StepNum>
                    <StepText
                      style={{
                        color: isDueDateNotSet(assignment.dueAt)
                          ? '#918b97'
                          : '#7200E0',
                      }}
                    >
                      Select the due time
                    </StepText>
                  </StepContainer>
                </StepsContainer>
              </StepsPart>
            )}
            <Frame1375>
              <TaskContainer>
                <TaskHeadingContainer>
                  <LeftStepNumber>STEP 1</LeftStepNumber>
                </TaskHeadingContainer>
                <TaskNameContainer>
                  <TaskName>
                    <TaskTitle>Name the task</TaskTitle>
                    <QuestionTooltip
                      text={'Create a title that accurately reflects the task'}
                      img={questionMark}
                    />
                  </TaskName>
                  <Frame1374
                    id="assignmentNameContainer"
                    onClick={cleanformattingTextBox}
                    onChange={handleTitleChange}
                  >
                    <TextInput
                      placeholder="Name of task"
                      id="assignmentName"
                      value={assignment.title}
                    />
                  </Frame1374>
                </TaskNameContainer>
              </TaskContainer>
              <TaskContainer>
                <TaskHeadingContainer>
                  <LeftStepNumber>STEP 2</LeftStepNumber>
                </TaskHeadingContainer>
                <TaskNameContainer>
                  <TaskName>
                    <TaskTitle>Select a class</TaskTitle>
                    <QuestionTooltip
                      text={'Which classes are you setting this task for?'}
                      img={questionMark}
                    />
                  </TaskName>
                  <Frame1299 id="classesContainer">
                    <Frame1298>{checkboxes}</Frame1298>
                  </Frame1299>
                </TaskNameContainer>
              </TaskContainer>
              <TaskContainer>
                <TaskHeadingContainer>
                  <LeftStepNumber>STEP 3</LeftStepNumber>
                </TaskHeadingContainer>
                <TaskNameContainer>
                  <TaskName>
                    <TaskTitle>Set up the task</TaskTitle>
                    <QuestionTooltip
                      text={'Add questions and/or instructions for the task'}
                      img={questionMark}
                    />
                  </TaskName>
                  <Frame1294>
                    <Frame1295>{questionFrames()}</Frame1295>
                  </Frame1294>
                </TaskNameContainer>
                <Frame1296>
                  <Buttons2
                    add={buttons21Props.add}
                    label="Add a new question"
                    onClickFn={addQuestion}
                  />
                </Frame1296>
              </TaskContainer>
              <TaskContainer>
                <TaskHeadingContainer>
                  <LeftStepNumber>STEP 4</LeftStepNumber>
                </TaskHeadingContainer>
                <TaskNameContainer>
                  <TitleAndSubtitleSubContainer>
                    <TaskName>
                      <TaskTitle>Select the feedback method</TaskTitle>
                      <QuestionTooltip
                        text={
                          "Choose between teacher-led feedback (with optional AI-suggestions), peer-to-peer feedback (where students review each other's work) or instant feedback from JeddAI using a teacher-trained AI marking model."
                        }
                        img={questionMark}
                      />
                    </TaskName>
                    <HeadingLine>
                      Choose between teacher-led feedback (with optional
                      AI-suggestions), peer-to-peer feedback (where students
                      review each other's work) or instant feedback from JeddAI
                      using a teacher-trained AI marking model.
                    </HeadingLine>
                  </TitleAndSubtitleSubContainer>
                  <Frame1295>
                    <Frame1299 id="DnDContainer">
                      <Frame12981>{feedbacksMethodContainer}</Frame12981>
                    </Frame1299>
                  </Frame1295>
                </TaskNameContainer>
              </TaskContainer>
              {isShowSelectPublishSchedule(assignment) && <TaskContainer>
                <TaskHeadingContainer>
                  <LeftStepNumber>STEP 5</LeftStepNumber>
                </TaskHeadingContainer>
                <TaskNameContainer>
                  <TaskName>
                    <TaskTitle>Select the publish time</TaskTitle>
                    <QuestionTooltip
                      text={'Select when to publish the task'}
                      img={questionMark}
                    />
                  </TaskName>
                  <Frame1299 id="publishTimeContainer">
                    <Frame12981>
                        <PublishTimeSelectionContainer />
                    </Frame12981>
                  </Frame1299>
                </TaskNameContainer>
              </TaskContainer>
              }
              {isShowPublishedStatus(assignment) && <TaskContainer>
                <TaskHeadingContainer>
                  <LeftStepNumber>STEP 5</LeftStepNumber>
                </TaskHeadingContainer>
                <TaskNameContainer>
                  <TaskName>
                    <TaskTitle>Task published</TaskTitle>
                  </TaskName>
                </TaskNameContainer>
                <HeadingLine>
                      Task published on {dayjs(assignment.publishedAt).format('MMMM D YYYY h:mm A')}
                    </HeadingLine>
              </TaskContainer>
              }
              <TaskContainer>
                <TaskHeadingContainer>
                  <LeftStepNumber>STEP 6</LeftStepNumber>
                </TaskHeadingContainer>
                <TaskNameContainer>
                  <TaskName>
                    <TaskTitle>Select the due time</TaskTitle>
                    <QuestionTooltip
                      text={'Set a due time for the task'}
                      img={questionMark}
                    />
                  </TaskName>
                  <Frame1299 id="dueTimeContainer">
                    <Frame12981>{dateSelectorFrame}</Frame12981>
                  </Frame1299>
                </TaskNameContainer>
              </TaskContainer>
            
            </Frame1375>
          </Frame1378>
          <Frame1377>
            <Frame1372>
              {saveButtons(
                assignment,
                saveDraft,
                showScheduledPopupHandler,
                publish,
                showPublishPopuphandler,
                showUpdatePopuphandler
              )}
            </Frame1372>
          </Frame1377>
        </Frame1379>
      </MainContainer>
    </>
  );
}

export default CreateAAssignmentLaptop;
