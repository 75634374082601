import { Schedule } from '@mui/icons-material';
import { assign } from 'lodash';

export const shouldShowComponent = (routesToHide) => {
  const currentHash = window.location.hash.split('?')[0];
  return !routesToHide.some((route) => currentHash.startsWith(route));
};

export const isTeacherOnboarding = (
  showTeacherOnboarding,
  mobileView,
  role
) => {
  return role !== 'STUDENT' && showTeacherOnboarding && !mobileView;
};

export const isShowWelcomeOnboarding = (
  showWelcomeOnboarding,
  mobileView,
  role
) => {
  return role !== 'STUDENT' && showWelcomeOnboarding && !mobileView;
};

export const isStudentOnboarding = (showStudentOnboarding) => {
  return showStudentOnboarding;
};

export const isShowPublishedStatus = (assignment) => {
  return assignment.status === 'PUBLISHED';
};

export const isShowSelectPublishSchedule = (assignment) => {
  return assignment.status !== 'PUBLISHED';
};

// export const publishButtonLabel = (assignment, scheduleCalendar) => {
//   if(assignment.status === 'PUBLISHED') {
//     return 'Save';
//   }else if (scheduleCalendar === SchedulerStates.PUBLISH_NOW) {
//     return 'Save and publish'
//   }
//   return 'Save and publish later';

// }

export const getPublishButtonConfig = (assignment) => {
  if (assignment.status === 'PUBLISHED') {
    return {
      label: 'Update',
      actionType: 'UPDATE',
    };
  }
  if (assignment.publishScheduledAt) {
    return {
      label: 'Save and publish later',
      actionType: 'SCHEDULED',
    };
  }
  return {
    label: 'Save and publish now',
    actionType: 'PUBLISH',
  };
};
