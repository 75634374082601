import React, { useMemo } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import dayjs from "dayjs";
import styled from "styled-components";

const DateSelector = ({ value, onChange, dateOnly = false }) => {
  const formattedValue = useMemo(() => {
    if (!value) {
      return null;
    }
    
    if (dateOnly) {
      return dayjs(value).startOf("day");
    }
    
    return dayjs(value);
  }, [value, dateOnly]);


  const handleDateAccept = (newValue) => {
    if (!newValue) return;
    const updatedValue = dateOnly ? dayjs(newValue).startOf("day") : dayjs(newValue);
    onChange(updatedValue);
  };

 
  const now = dayjs();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimeDateContainer>
        <DateContainer>
            <DesktopDateTimePicker
              value={formattedValue}
              onAccept={handleDateAccept} 
              onChange={() => {}} 
              ampm={false}
              minDateTime={now} 
              format="YYYY-MM-DD HH:mm"
              slotProps={{
                textField: {
                  variant: "outlined",
                  fullWidth: true,
                },
              }}
            />
    
        </DateContainer>
      </TimeDateContainer>
    </LocalizationProvider>
  );
};

export default React.memo(DateSelector);

const TimeDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  align-self: stretch;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  align-self: stretch;
  background-color: var(--white);
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid;
  height: 100%;
  width: 210px;
  border-color: var(--text);
  
  .MuiInputBase-root.MuiOutlinedInput-root 
    .MuiOutlinedInput-notchedOutline {
    border-color: var(--text);
  }
  
  .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input {
    font-size: 14px;
  }
    
  .MuiOutlinedInput-notchedOutline {
    border-radius: 12px;
    border: 0px solid var(--text);
  }
  
  fieldset {
    display: none;
  }
`;